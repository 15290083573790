import axiosInstance from './axios';

interface WalletAuthResponse {
  nonce: string;
  access_token?: string;
}

export const authService = {
  async authenticateWallet(address: string): Promise<WalletAuthResponse> {
    try {
      const response = await axiosInstance.post('/api/uss/oauth2/authorize', 
        new URLSearchParams({
          grant_type: 'nonce',
          redirect_uri: '',
          scope: 'user:read user:write user:delete',
          wallet_address: address
        }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async verifySignature(nonce: string, signature: string, address: string): Promise<WalletAuthResponse> {
    try {
      const response = await axiosInstance.post('/api/uss/oauth2/signature', {
        nonce: nonce,
        signature: signature,
        wallet_address: address
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};