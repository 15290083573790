import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { WalletState } from '@web3-onboard/core';
import { secureStorage } from '@/utils/encryption';

// Define a serializable wallet type
interface SerializableWallet {
  label: string;
  accounts: Array<{
    address: string;
    balance?: {
      type: string;
      asset: string;
      value: string;
    };
  }>;
  chains: Array<{ id: string; label: string }>;
}

interface WalletConnectionState {
  connectedWallet: SerializableWallet | null;
  isConnected: boolean;
}

const initialState: WalletConnectionState = {
  connectedWallet: null,
  isConnected: false
};

const walletConnectionSlice = createSlice({
  name: 'walletConnection',
  initialState,
  reducers: {
    setWalletConnection: (state, action: PayloadAction<{ wallet: WalletState }>) => {
      // Extract only serializable data from wallet
      const serializableWallet: SerializableWallet = {
        label: action.payload.wallet.label,
        accounts: action.payload.wallet.accounts.map(account => ({
          address: account.address,
          balance: account.balance
        })),
        chains: action.payload.wallet.chains
      };

      state.connectedWallet = serializableWallet;
      state.isConnected = true;
      
      // Store encrypted wallet info
      secureStorage.setItem('walletConnection', {
        wallet: serializableWallet,
        isConnected: true
      });
    },
    clearWalletConnection: (state) => {
      state.connectedWallet = null;
      state.isConnected = false;
      secureStorage.removeItem('walletConnection');
    },
    hydrateWalletConnection: (state) => {
      const stored = secureStorage.getItem('walletConnection');
      if (stored) {
        state.connectedWallet = stored.wallet;
        state.isConnected = stored.isConnected;
      }
    }
  }
});

export const { setWalletConnection, clearWalletConnection, hydrateWalletConnection } = walletConnectionSlice.actions;
export default walletConnectionSlice.reducer; 