import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '@/services/api/authService';
import { secureStorage } from '@/utils/encryption';

export const authenticateWallet = createAsyncThunk(
  'auth/authenticateWallet',
  async ({ address }: { address: string }, { rejectWithValue }) => {
    try {
      const response = await authService.authenticateWallet(address);
      return response;
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'response' in error) {
        const err = error as { response?: { data?: unknown } };
        return rejectWithValue(err.response?.data || 'Authentication failed');
      }
      return rejectWithValue('Authentication failed');
    }
  }
);

export const verifySignature = createAsyncThunk(
  'auth/verifySignature',
  async ({ nonce, signature, address }: { nonce: string, signature: string, address: string }, { rejectWithValue }) => {
    try {
      const response = await authService.verifySignature(nonce, signature, address);
      console.log('response: ', response)
      if (response.access_token) {
        secureStorage.setItem('auth_token', response.access_token);
        secureStorage.setItem('wallet_address', address);
        secureStorage.setItem('nonce', nonce);
      }
      return response;
    } catch (error: unknown) {
      if (error && typeof error === 'object' && 'response' in error) {
        const err = error as { response?: { data?: unknown } };
        return rejectWithValue(err.response?.data || 'Verification failed');
      }
      return rejectWithValue('Verification failed');
    }
  }
);

interface AuthState {
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  nonce: string | null;
  accessToken: string | null;
  walletAddress: string | null;
}

// Helper function to safely access localStorage
const getStorageValue = (key: string): string | null => {
  if (typeof window !== 'undefined') {
    return secureStorage.getItem(key);
  }
  return null;
};

// Create a function to get hydrated initial state
export const getInitialAuthState = (): AuthState => {
  return {
    isAuthenticated: !!getStorageValue('auth_token'),
    loading: false,
    error: null,
    nonce: getStorageValue('nonce'),
    accessToken: getStorageValue('auth_token'),
    walletAddress: getStorageValue('wallet_address')
  };
};

const authSlice = createSlice({
  name: 'auth',
  // Use the base initial state for SSR
  initialState: getInitialAuthState(),
  reducers: {
    // Add a reducer to hydrate the state on client side
    hydrateAuth: (state) => {
      const hydratedState = getInitialAuthState();
      state.accessToken = hydratedState.accessToken;
      state.walletAddress = hydratedState.walletAddress;
      state.nonce = hydratedState.nonce;
      state.isAuthenticated = hydratedState.isAuthenticated;
    },
    clearAuth: (state) => {
      state.isAuthenticated = false;
      state.nonce = null;
      state.accessToken = null;
      state.walletAddress = null;
      if (typeof window !== 'undefined') {
        secureStorage.removeItem('auth_token');
        secureStorage.removeItem('wallet_address');
        secureStorage.removeItem('nonce');
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticateWallet.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authenticateWallet.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.nonce = action.payload.nonce;
        state.walletAddress = action.meta.arg.address;
        secureStorage.setItem('nonce', action.payload.nonce);
        secureStorage.setItem('wallet_address', action.meta.arg.address);
      })
      .addCase(authenticateWallet.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(verifySignature.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifySignature.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.accessToken = action.payload.access_token || null;
        if (action.payload.access_token) {
          secureStorage.setItem('auth_token', action.payload.access_token);
        }
      })
      .addCase(verifySignature.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  }
});

export const { clearAuth, hydrateAuth } = authSlice.actions;
export default authSlice.reducer; 