"use client";

import { useState, useCallback, useEffect } from 'react';
import { ethers } from 'ethers';
import { onboard } from '@/utils/web3';
import { useToast } from '@/components/Toast/Toast';
import type { WalletState } from '@web3-onboard/core';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateWallet, verifySignature, clearAuth } from '@/store/authSlice';
import { AppDispatch } from '@/store/store';
import { setWalletConnection, clearWalletConnection, hydrateWalletConnection } from '@/store/walletConnectionSlice';
import { RootState } from '@/store/store';
import { fetchFraudEvaluationTargets } from '@/store/fraudEvaluationSlice';
import { useRouter } from 'next/navigation';

// --- Types ---
declare global {
  interface Window {
    ethereum?: {
      isMetaMask?: boolean;
      isCoinbaseWallet?: boolean;
    };
  }
}

export type ConnectedWallet = WalletState & {
  label: string;
  accounts: Array<{ 
    address: string; 
    balance: { 
      type: string; 
      asset: string; 
      value: string 
    } 
  }>;
  chains: Array<{ id: string; label: string }>;
};

export type SerializableWallet = {
  label: string;
  accounts: Array<{
    address: string;
    balance: {
      type: string;
      asset: string;
      value: string;
    };
  }>;
  chains: Array<{ id: string; label: string }>;
};

export type EthersProvider = ethers.BrowserProvider;

export type WalletConnection = {
  wallet: ConnectedWallet;
  provider: EthersProvider;
  signer: ethers.Signer;
} | null;

export type WalletError = {
  code: 'NO_WALLET' | 'CONNECTION_ERROR' | 'ENS_ERROR' | 'AUTH_ERROR' | 'NETWORK_ERROR';
  message: string;
};

// --- Constants ---
const TRUNCATE_LENGTH = {
  PREFIX: 6,
  SUFFIX: 4
};

const ERROR_MESSAGES = {
  NO_WALLET: 'No Web3 wallet extension detected.',
  WALLET_REQUIRED: 'Please install MetaMask or another Web3 wallet to continue.',
  AUTH_FAILED: 'Failed to authenticate wallet:',
  DISCONNECT_ERROR: 'Failed to disconnect wallet:',
  ENS_RESOLUTION_ERROR: 'Error resolving ENS name:',
  TIMEOUT: 'Operation timed out. Please try again.',
  ONBOARD_INIT_ERROR: 'Failed to initialize wallet connection UI.',
  NETWORK_ERROR: 'Network connection error. Please check your internet connection.'
};

const TOAST_TYPES = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info'
} as const;

const ENS_RESOLUTION_TIMEOUT = 3000; // 3 seconds

/**
 * A custom hook for handling Web3 wallet connection, authentication, and utility functions
 * @returns {Object} Web3 wallet utilities and state
 */
export const useWeb3 = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { addToast } = useToast();
  const router = useRouter();
  
  const walletConnection = useSelector((state: RootState) => state.walletConnection);
  const [error, setError] = useState<WalletError | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isDisconnecting, setIsDisconnecting] = useState<boolean>(false);
  const [isResolvingEns, setIsResolvingEns] = useState<boolean>(false);
  const [ensName, setEnsName] = useState<string | null>(null);

  /**
   * Check if a Web3 wallet is installed in the browser
   * @returns {boolean} Whether a wallet is installed
   */
  const checkWalletInstalled = useCallback((): boolean => {
    if (typeof window === 'undefined') return false;
    
    const isMetaMaskInstalled = Boolean(window.ethereum?.isMetaMask);
    const isCoinbaseWalletInstalled = Boolean(window.ethereum?.isCoinbaseWallet);
    
    return isMetaMaskInstalled || isCoinbaseWalletInstalled;
  }, []);

  /**
   * Initialize wallet connection from localStorage on mount
   */
  useEffect(() => {
    const initializeWallet = async () => {
      try {
        await dispatch(hydrateWalletConnection()).unwrap();
      } catch (error) {
        // Silent failure for hydration - user will need to reconnect
        setError({
          code: 'CONNECTION_ERROR',
          message: 'Failed to restore previous wallet connection.'
        });
      }
    };
    
    initializeWallet();
  }, [dispatch]);

  /**
   * Resolve ENS name for connected wallet address
   * @param {ethers.BrowserProvider} provider - Ethereum provider
   * @param {string} address - Ethereum address to resolve
   * @returns {Promise<string | null>} Resolved ENS name or null
   */
  const resolveEnsName = useCallback(async (
    provider: ethers.BrowserProvider, 
    address: string
  ): Promise<string | null> => {
    if (!address || !ethers.isAddress(address)) return null;
    
    setIsResolvingEns(true);
    
    try {
      // Add a timeout to prevent hanging if ENS resolution is slow
      const timeoutPromise = new Promise<null>((_, reject) => {
        setTimeout(() => reject(new Error(ERROR_MESSAGES.TIMEOUT)), ENS_RESOLUTION_TIMEOUT);
      });
      
      const ensPromise = provider.lookupAddress(address);
      const result = await Promise.race([ensPromise, timeoutPromise]);
      return result;
    } catch (error) {
      // Don't show toast for ENS errors as they're non-critical
      setError({
        code: 'ENS_ERROR',
        message: `${ERROR_MESSAGES.ENS_RESOLUTION_ERROR} ${error instanceof Error ? error.message : 'Unknown error'}`
      });
      return null;
    } finally {
      setIsResolvingEns(false);
    }
  }, []);

  /**
   * Connect to wallet and authenticate
   * @returns {Promise<WalletConnection>} Wallet connection or null
   */
  const connectWallet = useCallback(async (): Promise<WalletConnection> => {
    if (isConnecting) return null;
    
    try {
      setIsConnecting(true);
      setError(null);
      setEnsName(null);

      // Check if wallet is installed
      if (!checkWalletInstalled()) {
        const error: WalletError = {
          code: 'NO_WALLET',
          message: ERROR_MESSAGES.NO_WALLET
        };
        setError(error);
        addToast('Wallet Required', ERROR_MESSAGES.WALLET_REQUIRED, TOAST_TYPES.WARNING);
        return null;
      }

      // Connect with web3-onboard
      let wallets;
      try {
        wallets = await onboard.connectWallet();
      } catch (error) {
        setError({
          code: 'CONNECTION_ERROR',
          message: ERROR_MESSAGES.ONBOARD_INIT_ERROR
        });
        addToast('Connection Error', ERROR_MESSAGES.ONBOARD_INIT_ERROR, TOAST_TYPES.ERROR);
        return null;
      }
      
      // Check if user rejected or closed the wallet connection
      if (!wallets[0]) {
        return null;
      }

      const connectedWallet = wallets[0] as ConnectedWallet;
      
      // Create ethers provider
      let ethersProvider: ethers.BrowserProvider;
      let signer: ethers.Signer;
      
      try {
        ethersProvider = new ethers.BrowserProvider(connectedWallet.provider);
        signer = await ethersProvider.getSigner();
      } catch (error) {
        setError({
          code: 'CONNECTION_ERROR',
          message: error instanceof Error ? error.message : 'Failed to initialize provider'
        });
        addToast('Connection Error', 'Failed to initialize wallet provider', TOAST_TYPES.ERROR);
        return null;
      }
      
      const address = connectedWallet.accounts[0].address;
      
      if (!address || !ethers.isAddress(address)) {
        setError({
          code: 'CONNECTION_ERROR',
          message: 'Invalid wallet address'
        });
        addToast('Connection Error', 'Invalid wallet address', TOAST_TYPES.ERROR);
        return null;
      }

      try {
        // Authentication flow
        const authResult = await dispatch(authenticateWallet({
          address
        })).unwrap();
        
        const message = `To ensure your security, we need to verify your wallet connection. This is not a transaction — no funds will be moved or accessed. Simply connect your wallet to proceed:${authResult.nonce}`;
        
        // Request signature
        let signature: string;
        try {
          signature = await signer.signMessage(message);
        } catch (error) {
          // User rejected signature request
          setError({
            code: 'AUTH_ERROR',
            message: 'Signature request was rejected'
          });
          addToast('Authentication Failed', 'Signature request was rejected', TOAST_TYPES.ERROR);
          return null;
        }
        
        // Verify signature with backend
        const verifyResult = await dispatch(verifySignature({
          nonce: authResult.nonce,
          signature,
          address
        })).unwrap();

        console.log("verifyResult: ", verifyResult)

        if (!verifyResult?.refresh_token) {
          throw new Error('Signature verification failed');
        }

        // Fetch targets after successful authentication
        try {
          await dispatch(fetchFraudEvaluationTargets()).unwrap();
        } catch (error) {
          // Non-critical error, don't block wallet connection
          addToast(
            'Warning', 
            'Connected successfully, but failed to load some data. Please refresh.', 
            TOAST_TYPES.WARNING
          );
        }

        // Store only serializable wallet data in Redux
        const serializableWallet = mapToSerializableWallet(connectedWallet);
        
        dispatch(setWalletConnection({
          wallet: serializableWallet
        }));

        // Try to resolve ENS name in the background
        resolveEnsName(ethersProvider, address)
          .then(name => {
            if (name) setEnsName(name);
          })
          .catch(() => {
            // Silently fail ENS resolution
          });

        addToast('Connected', 'Wallet connected successfully', TOAST_TYPES.SUCCESS);

        // Return full wallet connection with provider and signer
        return {
          wallet: connectedWallet,
          provider: ethersProvider,
          signer
        };
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        setError({
          code: 'AUTH_ERROR',
          message: errorMessage
        });
        addToast('Authentication Failed', `${ERROR_MESSAGES.AUTH_FAILED} ${errorMessage}`, TOAST_TYPES.ERROR);
        return null;
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
      setError({
        code: 'CONNECTION_ERROR',
        message: errorMessage
      });
      addToast('Connection Error', errorMessage, TOAST_TYPES.ERROR);
      return null;
    } finally {
      setIsConnecting(false);
    }
  }, [dispatch, checkWalletInstalled, addToast, resolveEnsName, isConnecting]);

  /**
   * Disconnect wallet and clear authentication
   * @returns {Promise<void>}
   */
  const disconnectWallet = useCallback(async (): Promise<void> => {
    if (isDisconnecting) return;
    
    if (!walletConnection.connectedWallet) {
      return;
    }
    
    setIsDisconnecting(true);
    
    try {
      await onboard.disconnectWallet({ label: walletConnection.connectedWallet.label });
      dispatch(clearWalletConnection());
      dispatch(clearAuth());
      setEnsName(null);
      setError(null);
      addToast('Wallet Disconnected', 'Your wallet has been disconnected successfully', TOAST_TYPES.SUCCESS);
      router.push('/');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';
      setError({
        code: 'CONNECTION_ERROR',
        message: errorMessage
      });
      addToast('Disconnect Error', `${ERROR_MESSAGES.DISCONNECT_ERROR} ${errorMessage}`, TOAST_TYPES.ERROR);
    } finally {
      setIsDisconnecting(false);
    }
  }, [walletConnection.connectedWallet, dispatch, addToast, router, isDisconnecting]);

  /**
   * Get truncated wallet address or ENS name if available
   * @returns {string} Display address
   */
  const getWalletAddress = useCallback((): string => {
    if (!walletConnection.connectedWallet?.accounts[0]) return '';
    
    try {
      // Return ENS name if available
      if (ensName) return ensName;
      
      // Otherwise return truncated address
      const address = walletConnection.connectedWallet.accounts[0].address;
      return truncateAddress(address);
    } catch {
      return '';
    }
  }, [walletConnection.connectedWallet, ensName]);

  /**
   * Get full wallet address
   * @returns {string} Full address
   */
  const getFullWalletAddress = useCallback((): string => {
    if (!walletConnection.connectedWallet?.accounts[0]) return '';
    return walletConnection.connectedWallet.accounts[0].address;
  }, [walletConnection.connectedWallet]);

  return {
    wallet: walletConnection.connectedWallet,
    error,
    ensName,
    isResolvingEns,
    isConnecting,
    isDisconnecting,
    connectWallet,
    disconnectWallet,
    getWalletAddress,
    getFullWalletAddress,
    isConnected: walletConnection.isConnected,
    isWalletInstalled: checkWalletInstalled()
  };
};

// --- Helper Functions ---

/**
 * Map a connected wallet to a serializable format for Redux
 * @param {ConnectedWallet} wallet - Wallet to serialize
 * @returns {WalletState} Serialized wallet
 */
function mapToSerializableWallet(wallet: ConnectedWallet): WalletState {
  return {
    label: wallet.label,
    accounts: wallet.accounts,
    chains: wallet.chains,
    icon: wallet.icon || '',
    provider: wallet.provider
  };
}

/**
 * Truncate an Ethereum address for display
 * @param {string} address - Ethereum address
 * @returns {string} Truncated address
 */
function truncateAddress(address: string): string {
  if (!address || !ethers.isAddress(address)) return '';
  return `${address.slice(0, TRUNCATE_LENGTH.PREFIX)}...${address.slice(-TRUNCATE_LENGTH.SUFFIX)}`;
}